
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import { IBackOptions } from "@/models/BackOptions";
import { TabbarIds } from "@/enums/HousfyPage";
import { resolveRoutePath } from "@/router";
import { IPropertyInfo } from "@/models/PropertyInfo";
import PropertyHistoryShimmer from "@/components/PropertyHistoryShimmer.vue";
import PropertyHistory from "@/components/PropertyHistory.vue";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyAnchor } from "housfy-ui-lib";
import ContactPerson from "@/components/ContactPerson.vue";
import PropertyPageLayout from "@/components/layout/PropertyPageLayout.vue";
import { IRentalLeasing, IRentalLeasings } from "@/models/RentalLeasing";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { IAddress } from "@/models/Address";
import ErrorService from "@/services/ErrorService";
import api from "@/api";
import ContactService from "@/services/ContactService";
import LeasingsService from "@/services/LeasingsService";

@Component({
  name: "Historical",
  components: {
    HousfyPage,
    PropertyHistoryShimmer,
    PropertyHistory,
    HousfySvg,
    HousfyAnchor,
    ContactPerson,
    PropertyPageLayout,
  },
})
export default class Historical extends Vue {
  activeTabId = TabbarIds.ACTIVITY;
  address: IAddress | null = null;
  propertyInfo: IPropertyInfo | null = null;
  leasings: IRentalLeasings | null = null;
  loading = false;
  errorMessage: string | null = null;

  propertyUuid = this.$route.params.propertyUuid;

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }

  get headerTitle() {
    return this.address?.fullAddress || "";
  }

  get currentLeasing(): IRentalLeasing | undefined {
    return LeasingsService.getCurrentLeasing(this.leasings?.items);
  }

  get showContactPerson(): boolean {
    return ContactService.shouldShowContactPerson(
      this.propertyInfo,
      this.currentLeasing
    );
  }

  async mounted() {
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;

    try {
      [this.address, this.propertyInfo, this.leasings] = await Promise.all([
        api.property().retrieveAddress(this.propertyUuid),
        api.property().retrieveInfo(this.propertyUuid),
        api.property().retrieveLeasings(this.propertyUuid),
      ]);
    } catch (error) {
      this.errorMessage = ErrorService.getErrorFromCode(error);
    }

    this.loading = false;
  }
}
